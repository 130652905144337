@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./fonts.css";

html,
body,
#root {
  @apply h-full max-w-full w-full mx-auto;
  @apply font-roboto;
}

#root {
  @apply flex flex-col;
}

.light {
  --color-bg-primary: #f2f7fa;
  --color-bg-secondary: #f8fbfd;
  --color-bg-table-primary: #ffffff;
  --color-bg-table-secondary: #e6eff5;
  --color-bg-table-secondary-hover: #cce0eb;
  --color-bg-primary-blue: #00649c;
  --color-text-primary: #31414e;
  --color-text-secondary: #98a0a6;
  --color-text-primary-blue: #00649c;
  --color-border-primary: #cce0eb;
  --color-border-primary-blue: #00649c;
  --color-bg-disabled: #e6eff5;
  --color-bg-error-column: #ffe2e2;
}
.dark {
  --color-bg-primary: #21303b;
  --color-bg-secondary: #23313e;
  --color-bg-table-primary: #1a2734;
  --color-bg-table-secondary: #314657;
  --color-bg-table-secondary-hover: #597387;
  --color-bg-primary-blue: #42bbff;
  --color-text-primary: #ffffff;
  --color-text-secondary: #a9b6bf;
  --color-text-primary-blue: #42bbff;
  --color-border-primary: #597387;
  --color-border-primary-blue: #42bbff;
  --color-bg-disabled: #192228;
  --color-bg-error-column: #ffa7a7;
}

::-webkit-scrollbar {
  background-color: #f2f7fa;
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-thumb {
  background-color: #ccd5db;
  border-radius: 12px;
  border: 4px solid #f2f7fa;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #b0b8b9;
  border-width: 3px;
}

.dark ::-webkit-scrollbar {
  background-color: #21303b;
}

.dark ::-webkit-scrollbar-thumb {
  border-color: #21303b;
}

.darkmode-input div {
  color: #31414e;
  background-color: #f2f7fa;
  border-color: #cce0eb;
}

.darkmode-input div:hover {
  border-color: #00649c;
}

.dark .darkmode-input div {
  color: #ffffff;
  background-color: #21303b;
  border-color: #597387;
}

.dark .darkmode-input div:hover {
  border-color: #42bbff;
}

.title-dropdown .darkmode-input div {
  background-color: #ffffff;
}

.dark .title-dropdown .darkmode-input div {
  background-color: #1a2734;
}

.MuiMenuItem-root {
  color: #31414e;
  background-color: #f2f7fa;
}

.dark .MuiMenuItem-root {
  color: #ffffff;
  background-color: #21303b;
}

.MuiPaper-root {
  color: #31414e;
  background-color: #f2f7fa;
  border-color: #cce0eb;
}

.dark .MuiPaper-root {
  color: #ffffff;
  background-color: #21303b;
  border-color: #597387;
}

.sort-dropdown > div {
  width: 100%;
}

.sort-dropdown,
.sort-dropdown > div {
  color: #31414e !important;
  background-color: #ffffff !important;
  /* border-color: #cce0eb !important; */
  border: none !important;
}

.dark .sort-dropdown,
.dark .sort-dropdown > div {
  color: #ffffff !important;
  background-color: #1a2734 !important;
  border-color: #597387 !important;
}

.MuiBox-root .darkmode-input .react-select__option {
  color: #31414e !important;
  background-color: #f2f7fa !important;
}

.dark .MuiBox-root .darkmode-input .react-select__option {
  color: #ffffff !important;
  background-color: #21303b !important;
}

.MuiInputBase-input {
  color: #31414e !important;
  font-size: 12px !important;
}

.dark .MuiInputBase-input {
  color: #ffffff !important;
}

.dark .newsfeed-pagination .MuiInputBase-input {
  color: #31414e !important;
  background: none;
}

.react-select__input-container {
  background: none !important;
}

.total-amount-input::-webkit-inner-spin-button,
.total-amount-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.market-map .company:hover {
  background-color: #c0b1e3;
}

.classification-content {
  max-height: 65vh;
}

.section-shadow {
  box-shadow: 0px 21.7361px 65.2083px #e6eff5;
}

.dark .section-shadow {
  box-shadow: 0px 21.7361px 65.2083px #314657;
}

.react-datepicker__month.react-datepicker__monthPicker,
.react-datepicker__year {
  display: grid;
  grid-auto-flow: column;
}

.react-datepicker__year {
  grid-template-columns: max-content;
}

.date-after:after {
  content: "-";
  display: flex;
  position: absolute;
  right: -12px;
  color: #98a0a6;
}

.roadmap-cols {
  grid-auto-rows: 90vh;
  max-height: 70vh;
}

.roadmap-cols .chart-block {
  max-height: 68vh;
  max-width: 68vw;
  overflow: auto;
}

.roadmapTooltip {
  background-color: #f2f7fa !important;
  color: #31414e !important;
  border: 1px solid #cce0eb;
}

.dark .roadmapTooltip {
  background-color: #21303b !important;
  color: #ffffff !important;
  border-color: #597387;
}

.graphing-cols {
  /* grid-template-columns: repeat(auto-fit, minmax(364px, 1fr)); */
  grid-template-columns: calc(100% - 389px) 1fr;
  grid-auto-rows: 80vh;
}

.analysis-cols {
  grid-template-columns: calc(100% - 389px) 1fr;
  min-height: 600px;
}

.analysis-cols .chart-block {
  max-width: 66vw;
  min-width: 820px;
  min-height: 535px;
  max-height: 62vh;
}
.analysis-cols .chart-filter {
  max-height: 62vh;
  min-height: 535px;
}

.graphing-cols .chart-block {
  max-width: 66vw;
  min-width: 364px;
  min-height: 535px;
  max-height: 72vh;
}

.chart-block canvas {
  width: 100% !important;
}

.graphing-cols .chart-filter {
  min-height: 535px;
  max-height: 72vh;
}

.roadmap-chart {
  min-height: 58vh;
  max-height: 68vh;
  max-width: 66vw;
  min-width: 364px;
}

.roadmap-chart_ {
  min-height: 58vh;
  max-height: 65vh;
  max-width: 75vw;
  min-width: 364px;
}

.graphing-cols .chart-filter .bottom-buttons,
.analysis-cols .chart-filter .bottom-buttons {
  bottom: 24px;
  text-align: center;
  width: calc(100% - 48px);
  position: absolute;
}

.map-container {
  height: 296px;
}

.mapboxgl-control-container {
  display: none;
}

.formik-input input::placeholder,
.formik-textarea textarea::placeholder,
select::placeholder,
select option::placeholder {
  font-size: 12px;
  color: "#98A0A6";
}

/* select option {
  color: #31414e;
} */

select:invalid,
select:disabled,
select option[value=""] {
  color: #98a0a6 !important;
}

@media screen and (max-width: 768px) {
  .graphing-cols {
    grid-auto-flow: row;
    grid-template-columns: 100%;
  }
  .graphing-cols .chart-block,
  .graphing-cols .chart-filter,
  .roadmap-cols .chart-block,
  .roadmap-chart {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-width: 325px;
    min-height: auto;
  }
  .roadmap-cols .chart-block {
    min-width: 500px;
  }
  .roadmap-chart {
    min-width: 470px;
    width: 100%;
    min-height: auto;
  }
}

.gov-country-tooltip ul {
  list-style: none;
}

.gov-country-tooltip li {
  margin: 10px 15px;
}

.gov-country-tooltip ul li::before {
  content: "\2022";
  color: #00649c;
  font-weight: bold;
  display: inline-block;
  width: 15px;
  margin-left: -15px;
  font-size: 22px;
  position: relative;
  top: 5px;
}

.dark .gov-country-tooltip ul li::before {
  color: #42bbff;
}

.ql-bubble .ql-tooltip {
  z-index: 1;
}

.menu-w-shadow {
  top: 60px;
  left: 175px;
  /* box-shadow: 0px 10px 30px #e6eff5; */
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 14px;
  line-height: 140%;
  font-weight: normal;
  color: #31414e;
  background: #f2f7fa;
  position: absolute;
  z-index: 99;
  border: 1px solid #cce0eb;
}

.dark .menu-w-shadow {
  color: white;
  background: #21303b;
  border-color: #597387;
}

.react-datepicker-popper {
  z-index: 20 !important;
}

.rc-custom-tooltip.rc-tooltip {
  opacity: 1;
}

.rc-custom-tooltip .rc-tooltip-content .rc-tooltip-inner {
  background-color: #00649c;
  border-radius: 4px;
  font-size: 12px;
  padding: 3px 9px;
}

@media screen and (max-width: 768px) {
  .rc-custom-tooltip.rc-tooltip {
    display: none;
  }
}

.dark .rc-custom-tooltip .rc-tooltip-content .rc-tooltip-inner {
  background-color: #42bbff;
}

.rc-custom-tooltip .rc-tooltip-arrow {
  border-top-color: #00649c !important;
}

.dark .rc-custom-tooltip .rc-tooltip-arrow {
  border-top-color: #42bbff !important;
}

.dark .rc-slider-track {
  background-color: #42bbff !important;
}

.dark .rc-slider-handle {
  border-color: #42bbff !important;
}

.dark .rc-slider-dot.rc-slider-dot-active {
  background-color: #42bbff !important;
  border-color: #42bbff !important;
}

.gov-tooltip .mapboxgl-popup-tip {
  display: none;
}

.gov-tooltip .mapboxgl-popup-content,
.gov-tooltip .mapboxgl-popup-content > div {
  height: 100%;
}

.blured-block {
  background-image: url(../images/bg-blured.png);
}

.dark .blured-block {
  background-image: url(../images/bg-blured-dark.png);
}

/* .info-tooltip:hover .premium-tooltip {
  display: flex;
} */

.tooltip-wrapper {
  position: absolute;
  visibility: hidden;
}
.info-tooltip {
  /* position: unset !important; */
  overflow: unset !important;
}
.info-tooltip:hover .tooltip-wrapper {
  visibility: visible;
}

.table-td p {
  width: 100%;
  word-break: break-word;
  max-height: 200px;
  overflow-y: auto;
}

.table-td > div {
  height: 100%;
}

.summary-popup .mapboxgl-popup-content,
.summary-popup .mapboxgl-popup-content > div {
  height: 100%;
}

.summary-popup .mapboxgl-popup-content {
  padding: 7px 10px 8px !important;
}

.dark .summary-popup .mapboxgl-popup-content {
  background-color: #1a2734;
}

.heatmap-popup .mapboxgl-popup-content,
.heatmap-popup .mapboxgl-popup-content > div {
  height: 100%;
}

.heatmap-popup .mapboxgl-popup-content {
  padding: 8px 10px !important;
  border: 2px solid #00649c;
  border-radius: 6px;
}

.heatmap-popup .mapboxgl-popup-tip {
  display: none;
}

.bg-contain-size {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 100%;
}

.graphing-tool-free-placeholder {
  background: url(../images/free-users-graphing-tool-preview.png);
}

.dark .graphing-tool-free-placeholder {
  background: url(../images/free-users-graphing-tool-preview-dark.png);
}

.analysis-tool-free-placeholder {
  background: url(../images/free-users-analysis-tool-preview.png);
}

.dark .analysis-tool-free-placeholder {
  background: url(../images/free-users-analysis-tool-preview-dark.png);
}

.roadmap-free-placeholder {
  background: url(../images/free-users-roadmap-preview.png);
}

.dark .roadmap-free-placeholder {
  background: url(../images/free-users-roadmap-preview-dark.png);
}

.taxonomy-free-placeholder {
  background: url(../images/taxonomy-free-preview.png);
}

.dark .taxonomy-free-placeholder {
  background: url(../images/taxonomy-free-preview-dark.png);
}

.overflowunset {
  overflow: unset !important;
}

.qti_checkbox {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border: 1px solid #00649c;
  border-radius: 4px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

.qti_checkbox::before {
  content: "";
  width: 12px;
  height: 12px;
  transform: scale(0);
  border-radius: 2px;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #00649c;
  cursor: pointer;
}

.qti_checkbox:checked::before {
  transform: scale(1);
}

.checkboxOperator:checked {
  border: 1px solid #98a0a6 !important;
}

.checkboxOperator::before {
  box-shadow: inset 1em 1em #98a0a6 !important;
}

.newsFeedSearchInput {
  display: none;
}

.react-select__option:hover {
  text-decoration: underline;
}

.highlightedText {
  background-color: #e6eff5;
}
.dark .highlightedText {
  background-color: #597387;
}

.tradingview-widget-container > iframe {
  width: 100% !important;
  height: 100% !important;
}

.react-grid-item.react-grid-placeholder {
  background-color: #cce0eb !important;
}

.react-resizable-handle {
  padding: 5px !important;
}

.clickableItem {
  pointer-events: all !important;
}

.react-grid-item > .react-resizable-handle::after {
  display: none !important;
}

.activeEditMode .disableEvents {
  pointer-events: none !important;
}
